// import { createStore } from 'vuex'

// const store = createStore({
//     state() {
//         return {
//             user: {},
//             webApplicationToShow: [],
//             dataLowConnection: {},
//         }
//     },

// })

// export default store

import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = new Vuex.Store({
    state() {
        return {
            storedData: [],
            user: {},
            webApplicationToShow: [],
            dataLowConnection: {},
        }
    },
    mutations: {
        deleteEtadlByIndex(st, index) {
            if (st.storedData == undefined)
                st.storedData = []
            st.storedData.splice(index, 1)
        },
        setStoredData(st, val) {
            if (st.storedData == undefined)
                st.storedData = []
            st.storedData.push(val)
        },
        setOrStoredData(st, val) {
            if (st.oRstoredData == undefined)
                st.oRstoredData = []
            st.oRstoredData.push(val)
        },
        clearStoredData(st) {
            st.storedData = []
        }

    },
    plugins: [vuexLocal.plugin]
});

export default store