<template>
  <div v-if="showApp">
    <page-calendar></page-calendar>
  </div>
</template>

<script>
import PageCalendar from "@/projects/planning-disponibilite/components/page-calendar.vue";

export default {
  data() {
    return {
      showApp: false,
    };
  },
  mounted() {
    let appsToShow = this.$store.state.webApplicationToShow;
    let currentApp = this.$route.fullPath.replace("/", "").split('?')[0];
    console.log("currentApp", currentApp);
    
    if (appsToShow.includes(currentApp)) this.showApp = true;
  },
  components: {
    PageCalendar,
  },
};
</script>

<style>
</style>