import axios from "axios";
var CryptoJS = require("crypto-js");

class DataApi {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/contrat-gestion";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    get_material_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/material/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrat_informations(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_contrat_informations', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_sub_family_list(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_sub_family_list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_family_list(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_family_list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_clients(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_clients', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrats(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_contrats', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_parc(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_parc', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_stock(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_stock', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_or_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_techniciens(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_techniciens', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_retrait_list(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_retrait_list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agences(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agences', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_or(oRdata, callback) {
        let datas = { oRdata }
        axios.post(this.URL + '/v1/creat_new_or', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_moteur_line(motorData, callback) {
        let datas = { motorData }
        axios.post(this.URL + '/v1/creat_new_moteur_line', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_contrat(contratData, callback) {
        let datas = { contratData }
        axios.post(this.URL + '/v1/creat_contrat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_orListe_byLink(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/get_orListe_byLink', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_or_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/delete_or_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_or2_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/delete_or2_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_options(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_options', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    is_materiel_free(uuid, callback) {
        let datas = { uuid }
        axios.post(this.URL + '/v1/is_materiel_free', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    destock_materiel(uuid, callback) {
        let datas = { uuid }
        axios.post(this.URL + '/v1/destock_materiel', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_city_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_city_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    client_create(data, callback) {
        let datas = { data }
        axios.post(this.URL + '/v1/client_create', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_to_facturation(idLigne, numContrat, paiement, callback) {
        let datas = { idLigne, numContrat, paiement }
        axios.post(this.URL + '/v1/send_to_facturation', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    update_caution(idLigne, modeDepot, montant, callback) {
        let datas = { idLigne, modeDepot, montant }
        axios.post(this.URL + '/v1/update_caution', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrat_matricul_liste(contrat, callback) {
        let datas = { contrat }
        axios.post(this.URL + '/v1/get_contrat_matricul_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    renew_contrat(contrat, callback) {
        let datas = { contrat }
        axios.post(this.URL + '/v1/renew_contrat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    change_matricule(contrat, idcldata, matricule, nvMatricule, callback) {
        let datas = { contrat, idcldata, matricule, nvMatricule }
        axios.post(this.URL + '/v1/change_matricule', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    update_contrat(contrat, callback) {
        let datas = { contrat }
        axios.post(this.URL + '/v1/update_contrat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    facturation(id, contrat, paiement, callback) {
        let datas = { id, contrat, paiement }
        axios.post(this.URL + '/v1/facturation', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_mat_etadl(uid, contrat, balise, callback) {
        let datas = { uid, contrat, balise }
        axios.post(this.URL + '/v1/send_mat_etadl', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_mat_or(matricul, contrat, id, commente, callback) {
        let datas = { matricul, contrat, id, commente }
        axios.post(this.URL + '/v1/send_mat_or', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    sortie_mat(uid, contrat, callback) {
        let datas = { uid, contrat }
        axios.post(this.URL + '/v1/sortie_mat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    retour_mat(uid, contrat, callback) {
        let datas = { uid, contrat }
        axios.post(this.URL + '/v1/retour_mat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    cloture_contrat(contrat, callback) {
        let datas = { contrat }
        axios.post(this.URL + '/v1/cloture_contrat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_to_signe(id_web, uid, callback) {
        let datas = { id_web, uid }
        axios.post(this.URL + '/v1/send_to_signe', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency_list(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency_list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

}

export default new DataApi()