import fr from "@fullcalendar/core/locales/fr";
import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
import listPlugin from '@fullcalendar/list';
import { ref } from "@vue/reactivity";
import engine from '../core/DataApi'
import moment from 'moment';
import '@fullcalendar/core/vdom' // solves problem with Vite

export default function calendarData(eventClicked) {

    // const mapIsLoading = ref(false)
    const calendarEvents = ref([]);
    const calendarDate = ref({ start: undefined, end: undefined })

    const calendarOptions = computed(function() {
        let option = {
            views: {
                listDay: { buttonText: 'Jour' },
                listWeek: { buttonText: 'Semaine' },
                listMonth: { buttonText: 'Mois' },
            },
            height: "68vh",
            titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
            headerToolbar: {
                left: 'title',
                center: '',
                right: 'listDay,listWeek,listMonth prev,next'
            },
            noEventsContent: 'Aucun itinéraire à afficher',
            eventClick: eventClicked,
            // schedulerLicenseKey: '0359429366-fcs-1650894843',
            nowIndicator: true,
            initialView: 'listMonth',
            locale: fr,
            plugins: [listPlugin],
            events: calendarEvents.value,
            datesSet: updateCalendarView,
        }
        return option;
    })

    const updateCalendarData = function() {
        calendarEvents.value = []
        engine.getEvents(calendarDate.value.start, calendarDate.value.end, function(data) {
            if (data.code == "0") {
                let eventListe = data.data.data.map((val) => val.fieldData)
                let tmpEvent = []

                for (let i = 0; i < eventListe.length; i++) {
                    tmpEvent.push(convertEventFromFMdataToEventData(eventListe[i]))
                }
                calendarEvents.value = tmpEvent
            }
        })
    }

    const updateCalendarView = function(data) {
        calendarDate.value.start = parseInt(moment(data.startStr).format('x'))
        calendarDate.value.end = parseInt(moment(data.endStr).format('x'))
    }

    const convertEventToFMdata = function(event) {
        return {
            event_id: event.id,
            nom: event.name,
            description: event.title,
            debut: event.start,
            debut_timestamp: parseInt(moment(event.start).format('x')),
            fin_timestamp: parseInt(moment(event.start).add(event.minute.toFixed(1), 'm').format('x')),
            temps: event.time,
            distance: event.distance,
            poid: event.poid.join('|'),
            chauffeur: event.driver,
            // recap: JSON.stringify(itineraire)
        }
    }

    const convertEventFromFMdataToEventData = function(event) {
        return {
            id: event.event_id,
            name: event.nom,
            title: event.description,
            start: event.debut,
            time: event.temps,
            distance: event.distance,
            poid: event.poid.split('|').map(function(x) {
                return parseInt(x);
            }),
            driver: event.chauffeur,
            // recap: JSON.parse(event.recap),
            itineraire: JSON.parse(event.recap)
        }
    }

    onMounted(() => {
        updateCalendarData()
    })

    return {
        calendarEvents,
        calendarOptions,
        convertEventToFMdata,
        updateCalendarData,
    };
}