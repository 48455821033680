<template>
  <v-app>
    <v-main :style="{ 'background-color': getBackgroundColor }">
      <v-app-bar app fixed style="z-index: 1; left:0px;padding-right: 10px;" density="compact" v-if="getApp != 'LOGIN' && getApp.includes('/') == false &&
        (getApp != 'SIGNATURE DOUBLE AUTHENTIFICATION') && showHeader">
        <div v-if="!getApp == ''">
          <v-btn @click="goTo('/')" icon="mdi-arrow-left-circle" color="red" class="" style="font-size: 20px">
          </v-btn>
        </div>
        <v-app-bar-title class="py-1" style="border: 1px solid lightgrey; border-radius: 5px" align="center">
          <div v-if="getApp != ''">
            {{ getApp }}
          </div>
          <div v-else>
            {{ getAppBarTitile }}
          </div>
        </v-app-bar-title>
        <div v-if="!getApp != ''">
          <v-btn icon="mdi-logout" color="red" style="font-size:20px;" @click="logOut()"></v-btn>
        </div>
        <div v-else class="pl-2"></div>
        <WifiState style=""></WifiState>
      </v-app-bar>
      <v-col class="pa-0">
        <v-card :variant="'text'" :style="showHeader ? 'height: 94.5vh; overflow-y: auto' : ''">
          <v-col class="pa-1 pt-2 pb-0">
            <router-view v-if="!isLoading" />
            <div v-else>
              <v-col align="center" style="margin-top: 35vh" class="py-0">
                <v-progress-circular size="110" indeterminate color="primary"></v-progress-circular>
              </v-col>
            </div>
          </v-col>
        </v-card>
      </v-col>
    </v-main>
  </v-app>
</template>

<script>

import engine from "@/core/index";
import WifiState from "@/components/show_internet_state.vue"

export default {
  // name: "",
  data() {
    return {
      selectedPage: undefined,
      isLoading: true,
      webApplicationToShow: [],
      items: [
        {
          text: "Planning Disponibilité",
          href: "planning-disponibilite",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning Livraison",
          href: "planning-livraison",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning Technicien",
          href: "planning-technicien",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning gestion RDV vélo",
          href: "planning-gestion-rdv-velo",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Statistiques",
          href: "statistiques",
          color: "#FFF176",
          icon: "mdi-chart-bar",
          show: false,
        },
        {
          text: "Email visualisation",
          href: "email-visualisation",
          color: "#5C6BC0",
          icon: "mdi-email",
          show: false,
        },
        {
          text: "Etat des lieux materiel",
          href: "etat-des-lieux-materiel",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
        },
        {
          text: "Etat des lieux vélo",
          href: "etat-des-lieux-velo",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
        },
        {
          text: "Gestion livraison",
          href: "gestion-livraison",
          color: "#BDBDBD",
          icon: "mdi-map",
          show: false,
        },
        {
          text: "Signature en ligne",
          href: "signature-en-ligne",
          color: "#BDBDBD",
          icon: "mdi-draw",
          show: false,
        },
        {
          text: "SAV Gestion",
          href: "sav-gestion",
          color: "#BDBDBD",
          icon: "mdi-tools",
          show: false,
        },
        {
          text: "Contrat Gestion",
          href: "contrat-gestion",
          color: "#BDBDBD",
          icon: "mdi-file-document-outline",
          show: false,
        },
      ],
    };
  },
  mounted() {
    this.updateUser();
    this.selectedPage = window.location.pathname.replace("/", "");
  },
  methods: {
    updateUser() {
      let that = this;
      let token = localStorage.getItem("userToken");
      let nbrDivision = this.$route.path.split('/').filter((val) => val.length > 0).length
      if (nbrDivision <= 1) {
        engine.get_user_by_token(token, function (data) {
          if (data.code == 0) {
            that.webApplicationToShow =
              data.data.data.data[0].fieldData.web_application_to_show.split(",");
            for (let i = 0; i < that.webApplicationToShow.length; i++) {
              that.items.find(
                (val) => val.href == that.webApplicationToShow[i]
              ).show = true;
            }
            that.$store.state.webApplicationToShow = that.webApplicationToShow;
            let user = data.data.data.data[0].fieldData;
            delete user.user_id;
            delete user.user_password;
            that.$store.state.user = user;
          }
          // else {
          //   that.$router.push("/login");
          // }
          that.isLoading = false;
        });
      } else {
        that.isLoading = false;
      }
    },
    logOut() {
      // localStorage.clear();
      localStorage.setItem("userToken", undefined);
      this.goTo("/login");
    },
    goTo(href) {
      this.$router.push(href);
    },
  },
  computed: {

    getBackgroundColor() {
      let url = this.$route.fullPath.replace("/", "");
      let color = "#90A4AE";
      let colorTab = {
        "": "#E0E0E0",
        "login": "#E0E0E0",
        "planning-disponibilite": "#90A4AE",
        "planning-livraison": "#90A4AE",
        "planning-technicien": "#90A4AE",
        "planning-gestion-rdv-velo": "#90A4AE",
        "email-visualisation": "#90A4AE",
        "etat-des-lieux-materiel": "#90A4AE",
        "statistiques": "#90A4AE",
        "gestion-livraison": "#90A4AE",
        "signature-en-ligne": "#90A4AE",
        "sav-gestion": "#90A4AE",
      };
      if (colorTab[url] != undefined) color = colorTab[url];
      return color;
    },
    getAppBarTitile() {
      return "ACCEN - Web applications";
    },
    getApp() {

      if (this.$route.fullPath == '/')
        return ''

      let path = this.$route.fullPath
        .replace("/", "")
        .replaceAll("-", " ")
        .split("?")[0]
        .toUpperCase()

      return path;
    },
    showHeader() {
      // let show = ["/", "/login"];

      let query = this.$route.query

      if (query && query.header && query.header == 'hide')
        return false
      else
        return true
      // return !show.includes(this.$route.fullPath);
    },
    isHomePage() {
      let show = ["/"];
      return show.includes(this.$route.fullPath);
    },
  },
  components: {
    WifiState
  }
};
</script>

<style>
@import "./style.css";
</style>
