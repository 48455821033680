<template>
    <div style="" class="">
        <div align=center style=" background-color: white;border-radius: 100%;width: 30px;   border: solid 2px;" :style="{'border-color': speedLvl[getWifiLvl].color}">
            <v-icon :color="speedLvl[getWifiLvl].color" size="15" class="" >
                mdi-wifi-arrow-left-right
            </v-icon>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            speed: null,
            speedLvl: {
                0: {
                    color: 'red'
                },
                1: {
                    color: 'orange'
                },
                2: {
                    color: 'green'
                },
            },

        }
    },
    props : {
        getLvl:{type:Function}
    },
    watch : {
        getWifiLvl(val){
            this.getLvl(val)
        }
    },
    mounted() {
        this.updateNetworkSpeed();
    },
    computed: {
        getWifiLvl() {
            if (this.speed > 1) {
                return 2
            }
            if (this.speed > 0.3) {
                return 1
            }
            return 0
        },
    },
    methods: {
        updateNetworkSpeed() {
            this.measureNetworkSpeed();
            setInterval(() => {
                this.measureNetworkSpeed();
            }, 30000); // Update every 5 seconds
        },
        
    measureNetworkSpeed() {
      let that = this
      const startTime = performance.now();

      fetch('https://' + process.env.VUE_APP_MAIN_URL) // URL of the website you want to measure
        .then(response => {
          const endTime = performance.now();
          const duration = (endTime - startTime) / 100000; // in seconds
          that.speed = this.calculateSpeed(response.headers, duration)
        })
        .catch(error => {
          console.error('Error measuring network speed:', error);
          that.speed = null;
        });
    },

    calculateSpeed(headers, duration) {
      const contentLength = parseFloat(headers.get('Content-Length'));
      if (!contentLength || isNaN(contentLength)) {
        console.error('Content-Length header not found or invalid.');
        return null;
      }

      const fileSizeBytes = contentLength;
      const fileSizeMb = fileSizeBytes / 1024 / 1024;
      const speedMbps = (fileSizeMb / duration).toFixed(6); // calculate speed in Mbps
      return speedMbps;
    },

    }
}
</script>

<style></style>